/**
 * GameCard will track each live event for goals and will also control the display of the game
 */

import React, { Component } from 'react';
import { LiveGameDiff, Team } from '../../interfaces/live/liveData';
import { LiveGame } from '../../interfaces/live/liveGame';
import styles from './GameCard.module.scss';
import { Grid, Paper } from '@mui/material';
import {} from '../../logos';
import { WSH, ANA, ARI, BOS,BUF,CGY,CAR,CHI,COL,CBJ,DAL,DET,EDM,FLA,LAK,MIN,MTL,NSH,NJD,NYI,NYR,OTT,PHI,PIT,SJS,STL,SEA,TBL,TOR,VAN,VGK,WPG } from '../../react-nhl-logos/src/index'

interface GameCardProps {
  link: string;
}
interface GameCardState {
  lastUpdate?: string; //yyyymmdd_hhmmss format -- retrievable via metadata on LiveGame parent data
  loaded: boolean;
  period: number;
  time: string;
  home: {
    team: {
      id: number;
      name: string;
      abbreviation: string;
      link: string;
      triCode: string;
    };
    goals: {
      path: string;
      value: number;
    };
  },
  away: {
    team: {
      id: number;
      name: string;
      abbreviation: string;
      link: string;
      triCode: string;
    };
    goals: {
      path: string;
      value: number;
    };
  };
}

interface NHLUpdate {
  diff: {
    op: string;
    path: string;
    value: any;
  }[]
}

interface Logos {
  name: string;
  file: string;
}

class GameCard extends Component<GameCardProps, GameCardState>{
  constructor(props: GameCardProps){
    super(props);
    this.state = {
      period: 1,
      time: '00:00',
      loaded: false,
      home: {
        team: {
          id: 1,
          name: "",
          abbreviation: "",
          link: "",
          triCode: ""
        },
        goals: {
          path: this.PATH_HomeGoals,
          value: 0
        }
      },
      away: {
        team: {
          id: 1,
          name: "",
          abbreviation: "",
          link: "",
          triCode: ""
        },
        goals: {
          path: this.PATH_HomeGoals,
          value: 0
        }
      }
    };

    this.NHLAPI = this.NHLAPI + props.link;
  }

  NHLAPI: string = "https://statsapi.web.nhl.com";
  NHLDiff: string = "/diffPatch?startTimecode=";

  PATH_HomeGoals: string = '/liveData/linescore/teams/home/goals';
  PATH_AwayGoals: string = '/liveData/linescore/teams/away/goals';
  PATH_Timestamp: string = '/metaData/timeStamp';
  PATH_IntermissionTimeRemaining: string = '/liveData/linescore/intermissionInfo/intermissionTimeRemaining';
  PATH_PeriodTimeRemaining: string = '/liveData/linescore/currentPeriodTimeRemaining';
  PATH_EventTypeId: string = '/liveData/plays/currentPlay/result/eventTypeId';

  componentDidMount(){
    fetch(this.NHLAPI)//"https://statsapi.web.nhl.com/api/v1/game/2021021149/feed/live")
    .then(res => res.json())
    .then(
      (result: LiveGame) => {
        //console.log(result);
        //this.setState({LiveGa: result, loading: false})
        this.setState({
          period: result.liveData.linescore.currentPeriod,
          time: result.liveData.linescore.currentPeriodTimeRemaining,
          lastUpdate: result.metaData.timeStamp,
          home: {
            team: result.liveData.boxscore.teams.home.team,
            goals: {
              path: "/liveData/linescore/teams/home/goals",
              value: result.liveData.linescore.teams.home.goals
            }
          },
          away: {
            team: result.liveData.boxscore.teams.away.team,
            goals: {
              path: "/liveData/linescore/teams/away/goals",
              value: result.liveData.linescore.teams.away.goals
            }
          },
          loaded: true
        });
      },
      (error) => {
        console.log(error);
      }
    );
    setInterval( () => {
      this.update()
    }, 10000);
  }
  getImageComponent(abbreviation: string){
    var size = 70;
    switch(abbreviation){
      case "ANA":
        return <ANA size={size} />;
      case "BOS":
        return <BOS size={size} />;
      case "NJD":
        return <NJD size={size} />;
      case "PHI":
        return <PHI size={size} />;
      case "WPG":
        return <WPG size={size} />;
      case "MTL":
        return <MTL size={size} />;
      case "NYR":
        return <NYR size={size} />;
      case "CHI":
        return <CHI size={size} />;
      case "VGK":
        return <VGK size={size} />;
      case "ARI":
        return <ARI size={size} />;
      case "DAL":
        return <DAL size={size} />;
      case "LAK":
        return <LAK size={size} />;
      case "SEA":
        return <SEA size={size} />;
      case "WSH":
        return <WSH size={size} />;
      case "NYI":
        return <NYI size={size} />;
      case "BUF":
        return <BUF size={size} />;
      case "FLA":
        return <FLA size={size} />;
      case "OTT":
        return <OTT size={size} />;
      case "CAR":
        return <CAR size={size} />;
      case "TBL":
        return <TBL size={size} />;
      case "CBJ":
        return <CBJ size={size} />;
      case "CGY":
        return <CGY size={size} />;
      case "MIN":
        return <MIN size={size} />;
      case "NSH":
        return <NSH size={size} />;
      case "COL":
        return <COL size={size} />;
      case "SJS":
        return <SJS size={size} />;
      case "EDM":
        return <EDM size={size} />;
      case "PIT":
        return <PIT size={size} />;
      case "DET":
        return <DET size={size} />;
      case "STL":
        return <STL size={size} />;
      case "TOR":
        return <TOR size={size} />; 
      default:
        return <VAN size={size} />;
    }
  }

  update(){
    //console.log(this.state);
    fetch(`${this.NHLAPI}${this.NHLDiff}${this.state.lastUpdate}`)
    .then( res => res.json())
    .then(
      (result) => {
        if(result.length === 0){ 
          //no updates shown
          console.log("no update");
        }else{
          //check if its an update or a full gamelive data
          //console.log(result);
          if((result as NHLUpdate[])[0] === null || (result as NHLUpdate[])[0] === undefined){ //NHL LIVE GAME FULL DETAILS
            console.log("NHL LIVE UPDATE");
            //console.log(this.state.home.team.name);
            var liveupdate = result as LiveGame;
            //console.log(liveupdate);
            this.setState({lastUpdate: liveupdate.metaData.timeStamp});
          }else{ //NHL DIFF UPDATES
            
            console.log("NHL DIFF UPDATE");
            //console.log(this.state.home.team.name);
            var nhlupdate = result as NHLUpdate[];
            console.log(nhlupdate);
            var timestamp = nhlupdate[nhlupdate.length - 1].diff.filter( entry => entry.path === this.PATH_Timestamp)[0];
            this.setState({lastUpdate: timestamp.value});

            nhlupdate.forEach(update => {
              //CHECK FOR A GOAL
              let goalEvent = update.diff.filter( event => event.path === "/liveData/plays/currentPlay/result/event" && event.value === "Goal");
              if(goalEvent.length > 0){ //Goal Happened so lets get the details
                /** Update goal counter && invoke goal event on main ticker (send goal and assist details) */
                console.log("goal happened");
                let homeGoals = update.diff.filter( event => event.path === "/liveData/linescore/teams/home/goals");
                let awayGoals = update.diff.filter( event => event.path === "/liveData/linescore/teams/away/goals");

                if(homeGoals.length > 0){
                  this.setState(prevState => {
                    let home =  Object.assign({}, prevState.home);
                    home.goals.value = homeGoals[homeGoals.length - 1].value;
                  });
                }
                if(awayGoals.length > 0 ){
                  this.setState(prevState => {
                    let away =  Object.assign({}, prevState.away);
                    away.goals.value = awayGoals[awayGoals.length - 1].value;
                  });
                }
              }

              //CHECK FOR PERIOD TIME REMAINING
              let timeUpdate = update.diff.filter( event => event.path === "/liveData/linescore/currentPeriodTimeRemaining");
              if(timeUpdate.length > 0){
                this.setState({time: timeUpdate[timeUpdate.length - 1].value})
              }

              //CHECK CURRENT PERIOD
              fetch(this.NHLAPI)
              .then(res => res.json())
              .then(
                (result: LiveGame) => {
                  this.setState( { period: result.liveData.linescore.currentPeriod});
                });
            });

            
          }
        }
        
      }
    );
  }

  initiateGoal(){
  }

  render() {
    return (
      this.state.loaded && (
        <Paper className={styles.GameCard} elevation={12}>
          {/** TEAM BAR */}
          <Grid container justifyContent="space-between" className={styles.logobar} alignItems="center">
            <Grid item xs={5}>
              {this.getImageComponent(this.state.away.team.abbreviation)}<br/>
              {/**<img src={this.getImageComponent(this.state.away.team.abbreviation)} className={styles.logo} /><br/>*/}
              {this.state.away.team.abbreviation}
            </Grid>
            <Grid item><p className={styles.valign}>@</p></Grid>
            <Grid item xs={5}>
              {this.getImageComponent(this.state.home.team.abbreviation)}<br/>
              {/**<img src={this.getImageComponent(this.state.home.team.abbreviation)} className={styles.logo} /><br/>*/}
              {this.state.home.team.abbreviation}
            </Grid>
          </Grid>
          {/** ACTION BAR -- Game Readout */}
          <Grid container justifyContent="space-between" className={`${styles.actionbar}`} alignItems="center">
            <Grid item xs={3}>
              <p>{this.state.away.goals.value}</p>
            </Grid>
            <Grid item xs={6}>
                {this.state.time !== "Final" ? (<p>Period {this.state.period}<br/>{this.state.time}</p>) : (<p>{this.state.time}</p>)}
            </Grid>
            <Grid item xs={3}><p>{this.state.home.goals.value}</p></Grid>
          </Grid>
        </Paper>
      )
      /*
      <div className={styles.GameCard}>
        { this.state.loaded && ( <p>{this.state.home.team.name} -- {this.state.home.goals.value} vs {this.state.away.team.name} -- {this.state.away.goals.value}</p> )  }
      </div>
      */
    )
  }
}

export default GameCard;

/**
 * GameTicker gets all Games on the days schedule and will display
 */
import { Fade, Stack } from '@mui/material';
import React, { Component } from 'react';
import { Transition } from 'react-transition-group';
import { Schedule } from '../../interfaces/schedule';
import GameCard from '../GameCard/GameCard';
import styles from './GameTicker.module.scss';

interface GameTickerProps {  
}
interface GameArray {
  gameComponent: React.ReactElement;
  time: number;
}
interface GameTickerState {
  GameSchedule: Schedule;
  loading: boolean;
  gameArray: GameArray[];
}

class GameTicker extends Component<GameTickerProps, GameTickerState>{
  constructor(props: GameTickerProps){
    super(props);
    this.state = {
      loading: true,
      GameSchedule: {
        copyright: "",
        dates: [],
        metaData: {
          timeStamp: ""
        },
        totalEvents: 0,
        totalGames: 0,
        totalItems: 0, 
        totalMatches: 0,
        wait: 0
      },
      gameArray: []
    }
  }
  gameArray: GameArray[] = [];
  interval = 5000;

  componentDidMount(){
    fetch("https://statsapi.web.nhl.com/api/v1/schedule")
    .then(res => res.json())
    .then(
      (result: Schedule) => {
        let array : GameArray[] = [];
        result.dates[0].games.map( (game, key) => {
          array.push({gameComponent: <GameCard key={key} link={game.link} />, time: 60*key})
        });
        this.gameArray = array;
        this.setState({GameSchedule: result, loading: false, gameArray: array});
      },
      (error) => {
        console.log(error);
      }
    );
    setInterval( () => {
      this.update()
    }, this.interval);

  }

  update(){
    let gameArray = this.state.gameArray;
    gameArray.sort((a,b) => a.time - b.time);
    gameArray.map((game, index) => {
      gameArray[index].time = game.time + this.interval;
    });
    gameArray[0].time = 0;
    gameArray[1].time = 0;
    this.setState({gameArray: gameArray});
    console.log(gameArray);
  }
  
  swap(){

  }


  render() {

    return (
      <div className={styles.GameTicker}>
        <Stack direction="row" className={styles.GameStacker} spacing={1}>
          {!this.state.loading && this.state.gameArray.map((game, index) => {
            return (
              <div>{game.gameComponent}</div>
            )
          })}
        </Stack>
      </div>
    );
  }

}
export default GameTicker;

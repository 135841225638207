import React, { FC } from 'react';
import styles from './DraftBoard.module.scss';

interface DraftBoardProps {}

const DraftBoard: FC<DraftBoardProps> = () => (
  <div className={styles.DraftBoard}>
    DraftBoard Component
  </div>
);

export default DraftBoard;

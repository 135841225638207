import React from "react";
import PropTypes from "prop-types";

const SEA = props => {
  const { size } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      version="1.1"
      viewBox="0 0 288 288"
    >
		<path fill="#001425" d="M29.31,234.51c56.12,4.91,91.71,20.73,109.03,53.49c59.31-9.61,114.17-47.65,114.17-96.69
			c0-65.33-79.15-75.6-81.26-94.63c33.29,12.07,69.94-0.63,87.43-42.17C206.97,50.69,169.11,31.83,152.74,0
			C89.31,11.29,38.57,52.34,38.57,96.69c0,64.77,83.25,81.92,92.57,95.66C85.71,175.12,46.52,194.75,29.31,234.51z"/>
		<path fill="#96D8D8" d="M127.75,93.34c-7.1-7.21-10.06-12.86-10.06-20.32c0-8.53,4.11-12.7,4.11-17.48c0-4.1-2.58-7.32-7.52-8.3
			c2.93-3.16,6.91-5.06,10.6-5.06c21.17,0,27.42,46.79,71.59,45.11c15.74-1.51,30.62-9.16,39.97-22.58
			c-38.57-5.01-73.78-21.4-90.99-49.26c0,0,0,0,0,0c-17.85,3.25-92.69,29.08-90.8,83.06c1.97,56.29,85.22,71.94,108.51,96.11
			c18.36,19.06,5.43,38.27-6.41,45.25c-18.28-47.55-78.37-50.93-102.29-16.61c38.57,5.01,73.78,21.4,90.99,49.26
			c42.55-8.93,63.07-46.24,55.26-73.15c-12.93-44.54-96.88-52.37-119.15-94.09c-11.9-23.47-2.12-48.53,19.64-63.11
			c-8.54,11.01-12.62,23.69-12.44,35.56c1.93,2.1,2.75,7.04,2.77,7.14c0.28,1.72,0.79,5.36-0.11,7.59c0.95,2.5,2.17,4.88,3.63,7.16
			c2.91,0.73,6.19,4.53,6.25,4.61c3.2,3.68,3.7,6.01,3.78,6.89c1.98,1.77,4.1,3.47,6.35,5.13c1.87-0.22,4.73,0.69,7.63,2.53
			c3.34,2.19,5.01,4.36,5.48,5.98c2.33,1.36,4.72,2.69,7.16,4c1.87-0.51,4.95,0.08,8.04,1.67c3.1,1.59,5.38,3.76,6.05,5.59
			c2.45,1.22,4.92,2.45,7.4,3.67c1.87-0.54,4.98,0,8.1,1.59c3.05,1.56,5.29,3.7,6,5.51c2.53,1.31,5.03,2.64,7.5,3.99
			c2.3-0.47,5.96,0.93,7.96,2.12c2.95,1.76,4.97,4.05,5.57,5.9c2.5,1.62,4.91,3.29,7.22,5.01c1.95-0.09,4.83,1.03,7.51,3.31
			c2.89,2.52,4.38,5.29,4.44,7.21c2,2.14,3.84,4.36,5.48,6.69c2.37,0.59,4.75,3.33,6.08,6.08c1.84,4.01,1.6,6.83,1.06,8.29
			c3.19,9.96,4.05,22.51-1.08,36.94c-0.05,0.15-0.12,0.33-0.2,0.51c10.74-11.7,18.01-26.1,17.4-43.39
			C234.28,133.16,151.04,117.51,127.75,93.34z"/>
		<path fill="#C8102E" d="M159.7,99.24c-9.51-10.94-15.59-30.34-26.08-37.91C128.92,78.03,138.71,96.3,159.7,99.24z"/>
		<path fill="#639FB6" d="M152.16,256.21c-3.43,10.06-6.71,16.3-6.71,16.3c-17.2-27.86-52.42-44.25-90.99-49.26
			C94.32,203.27,135.58,224.29,152.16,256.21z M236.44,64.69c-39.85,19.98-81.12-1.04-97.7-32.97c3.43-10.06,6.71-16.3,6.71-16.3
			c-16.85,3.07-92.71,28.62-90.8,83.06c1.97,56.29,85.22,71.94,108.51,96.11c18.36,19.06,5.43,38.27-6.41,45.25
			c-1.35,6.12-2.99,11.65-4.59,16.36c8.67-1.51,34.96-15.43,35.13-42.21c0.24-36.91-47.47-46.69-83.62-69.09
			c-29.76-18.44-35.54-36.73-37.05-48.31c-3.55-27.24,15.67-52.12,43.62-63.85c-4.01,3.42-7.12,6.75-9.04,9.41
			c-8.54,11.01-12.62,23.69-12.44,35.56c1.93,2.11,2.75,7.04,2.77,7.14c0.28,1.72,0.79,5.36-0.11,7.59c0.95,2.5,2.17,4.88,3.63,7.16
			c2.91,0.73,6.19,4.53,6.25,4.61c3.2,3.68,3.7,6.01,3.78,6.89c1.98,1.77,4.1,3.47,6.35,5.13c1.87-0.22,4.73,0.69,7.63,2.53
			c3.34,2.19,5.01,4.36,5.48,5.98c2.33,1.36,4.72,2.69,7.16,4c1.87-0.51,4.95,0.08,8.04,1.67c3.1,1.59,5.38,3.76,6.05,5.59
			c2.45,1.22,4.92,2.45,7.4,3.67c1.87-0.54,4.98,0,8.1,1.59c3.05,1.56,5.29,3.7,6,5.51c2.53,1.31,5.03,2.64,7.5,3.99
			c2.3-0.47,5.96,0.93,7.96,2.12c2.95,1.76,4.97,4.05,5.57,5.9c2.5,1.62,4.91,3.29,7.22,5.01c1.95-0.09,4.83,1.03,7.51,3.31
			c2.89,2.52,4.38,5.29,4.44,7.21c2,2.14,3.84,4.36,5.48,6.69c2.37,0.59,4.75,3.33,6.08,6.08c1.84,4.01,1.6,6.83,1.06,8.29
			c3.19,9.96,4.05,22.51-1.08,36.94c13.73-23.27,12.41-46.38-2.94-65.91c-32.08-40.82-112.79-45.69-112.49-92.48
			c0.07-11.13,4.72-19.98,10.67-26.7c2.93-3.16,6.91-5.06,10.6-5.06c21.17,0,27.42,46.79,71.59,45.11
			C212.22,85.76,227.1,78.11,236.44,64.69C236.44,64.69,236.44,64.69,236.44,64.69C236.44,64.69,236.44,64.69,236.44,64.69z"/>
    </svg>
  );
};

SEA.propTypes = {
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

SEA.defaultProps = {
  size: "100"
};

export default SEA;
